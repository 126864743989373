import Vue from 'vue'

export default {
  initChat(id) {
    return Vue.axios.post('/dashboard/chats/init', { userId: id })
  },
  sendImages(files) {
    return Vue.axios.post('/dashboard/chat/media', files, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  getImage(id, key) {
    return Vue.axios.post(
      `/dashboard/chat/media/download`,
      { id, key },
      { responseType: 'arraybuffer' }
    )
  }
}
