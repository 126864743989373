var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.owner ? _vm.$style.chat__message : _vm.$style.chat__answer]},[_c('span',[_c('div',{class:[
        _vm.$style.chat__message__images,
        { [_vm.$style.chat__message__images__answer]: _vm.owner }
      ]},_vm._l((_vm.images),function(image,index){return _c('div',{key:index,class:[
          _vm.$style.chat__message__img,
          { [_vm.$style.chat__message__img_open]: _vm.openImageId === image.id }
        ],on:{"click":function($event){return _vm.setOpenImage(image.id)}}},[_c('img',{attrs:{"src":image.src,"alt":""}})])}),0),_c('p',[_vm._v(_vm._s(_vm.msg.text))]),_c('p',{staticClass:"datetime"},[_vm._v(_vm._s(_vm.getDate(_vm.msg.createdAt))+" "+_vm._s(_vm.getTime(_vm.msg.createdAt)))]),(_vm.owner)?_c('svg',{attrs:{"width":"89","height":"25","viewBox":"0 0 89 25","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M88.9297 0.535278L0.646309 0.535277L0.646305 24.7878L88.9297 0.535278Z","fill":"#E2E7E9"}})]):_c('svg',{attrs:{"width":"89","height":"30","viewBox":"0 0 89 35","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 0.206543L88.2834 0.206541L88.2834 34.8219L0 0.206543Z","fill":"#7F7F7F"}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }