<template>
  <div :class="[owner ? $style.chat__message : $style.chat__answer]">
    <span>
      <div
        :class="[
          $style.chat__message__images,
          { [$style.chat__message__images__answer]: owner }
        ]"
      >
        <div
          v-for="(image, index) in images"
          :key="index"
          :class="[
            $style.chat__message__img,
            { [$style.chat__message__img_open]: openImageId === image.id }
          ]"
          @click="setOpenImage(image.id)"
        >
          <img :src="image.src" alt="" />
        </div>
      </div>

      <p>{{ msg.text }}</p>

      <p class="datetime">{{ getDate(msg.createdAt) }} {{ getTime(msg.createdAt) }}</p>

      <svg
        v-if="owner"
        width="89"
        height="25"
        viewBox="0 0 89 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M88.9297 0.535278L0.646309 0.535277L0.646305 24.7878L88.9297 0.535278Z"
          fill="#E2E7E9"
        />
      </svg>

      <svg
        v-else
        width="89"
        height="30"
        viewBox="0 0 89 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0.206543L88.2834 0.206541L88.2834 34.8219L0 0.206543Z" fill="#7F7F7F" />
      </svg>
    </span>
  </div>
</template>

<script>
import chatApi from '@/api/chat'
import Moment from 'moment'

export default {
  name: 'ChatMessage',
  props: {
    msg: {
      type: Object,
      default: () => {}
    },
    owner: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      images: [],
      openImageId: null
    }
  },
  computed: {
    moment() {
      return Moment
    }
  },
  mounted() {
    setTimeout(async () => {
      await this.fetchImage()
    }, 1000)
  },
  methods: {
    getDate(value) {
      return this.moment(value).format('YYYY-MM-DD')
    },
    getTime(value) {
      return this.moment(value).format('HH:mm:ss')
    },
    async fetchImage() {
      if (this.msg?.media?.length) {
        try {
          this.$store.commit('setChatLoadingStatus', true)

          for (const image of this.msg.media) {
            const resp = await chatApi.getImage(this.msg.id, image.key)

            if (resp?.status === 200) {
              const buffer = Buffer.from(resp.data)
              const base64String = buffer.toString('base64')
              this.images.push({ id: image.key, src: `data:image/jpg;base64,${base64String}` })
            }
          }
        } catch (error) {
          console.warn(error) // eslint-disable-line

          this.$store.commit(
            'setNoticeData',
            `<p style="margin: 2rem 3rem; font-size: 14px">Ошибка получения файла с сервера!</p>`
          )

          this.$store.commit('setNotice', 'ErrorNotice')

          setTimeout(() => {
            this.$store.commit('setNoticeData', null)
            this.$store.commit('setNotice', null)
          }, 5000)
        } finally {
          this.$store.commit('setChatLoadingStatus', false)
        }
      }
    },

    setOpenImage(imageId) {
      if (this.openImageId === imageId) {
        this.openImageId = null
      } else {
        this.openImageId = imageId
      }
    }
  }
}
</script>

<style lang="scss">
.datetime {
  font-size:0.6rem;
  display: flex;
  justify-content:end;
}
</style>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.chat {
  &__message {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 1.25rem;
    word-break: break-all;

    span {
      position: relative;
      min-width: 10rem;
      min-height: 3rem;
      padding: 0.688rem 0.938rem;
      background: $chat-gray;
      border-radius: 1rem;
      font-size: 1.5rem;

      svg {
        position: absolute;
        top: 0.75rem;
        right: -2.25rem;
        z-index: -1;
      }
    }

    &__images {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__answer {
        align-items: flex-end;
      }
    }

    &__img {
      overflow: hidden;
      display: flex;
      width: 10rem;
      margin: 0.5rem;
      border-radius: 1rem;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &_open {
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
      }
    }
  }

  &__answer {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 1.25rem;
    word-break: break-all;

    span {
      position: relative;
      min-width: 10rem;
      min-height: 3rem;
      padding: 0.688rem 0.938rem;
      background: $gray;
      border-radius: 1rem;
      color: $white;
      font-size: 1.5rem;

      svg {
        position: absolute;
        top: 0.75rem;
        left: -2.25rem;
        z-index: -1;
      }
    }
  }
}
</style>
