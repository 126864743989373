<template>
  <div :class="$style.chat">
    <div>
      <div :class="$style.chat__wrapper">
        <custom-input
          v-model="userId"
          :class="$style.chat__address"
          placeholder="Введите id пользователя"
        />

        <transition name="fade">
          <div v-if="user" :class="$style.chat__userInfo">
            <p :class="$style.chat__userInfo__text">Выбран пользователь с id: {{ user.id }}</p>
            <p :class="$style.chat__userInfo__text">{{ getUserInfo(user) }}</p>
          </div>
        </transition>

        <transition name="select">
          <div v-if="users.length" :class="$style.chat__dropdown">
            <p
              v-for="user in users"
              :key="user.id"
              :class="$style.chat__user"
              @click="setUser(user)"
            >
              {{ getUserInfo(user) }}
            </p>
          </div>
        </transition>

        <div v-if="users.length" :class="$style.chat__close" @click="resetUsers" />
      </div>
    </div>

    <div ref="block" :class="$style.chat__list">
      <Message
        v-for="(msg, index) in messages"
        :key="`msg-${index}`"
        :msg="msg"
        :owner="msg.userId != null"
      />
    </div>

    <div v-if="images.length" :class="$style.chat__images">
      <div v-for="image in images" :key="image.id" :class="$style.chat__image">
        <img :class="$style.chat__image__img" :src="image.src" alt="" />
        <div :class="$style.chat__image__delete" @click="deleteImage(image.id)">
          <img src="@/assets/icons/close-green.svg" alt="delete" />
        </div>
      </div>
    </div>

    <div :class="$style.chat__wrapper">
      <Input v-model="message" :class="$style.chat__wrapper__input" @enter="sendMessage" />
      <Button :class="$style.chat__wrapper__button" type="tertiary" @click="sendMessage">
        Отправить
      </Button>
    </div>

    <Button :class="$style.chat__button" type="tertiary" @click="goBack">
      Закрыть чат <img src="@/assets/icons/save.svg" alt="save" />
    </Button>
  </div>
</template>

<script>
import Message from '@/basic/ChatMessage'
import Input from '@/basic/ChatInput'
import Button from '@/basic/Button'
import CustomInput from '@/basic/Input'

import { mapGetters, mapMutations } from 'vuex'

import chatApi from '@/api/chat'
import usersApi from '@/api/users'
// import users from '@/api/users'

export default {
  components: {
    Message,
    Input,
    Button,
    CustomInput
  },
  data() {
    return {
      message: '',
      media: [],

      teachers: [],
      chatId: '',

      users: [],
      user: null,
      userId: null
    }
  },
  computed: {
    ...mapGetters({
      chat: 'chat',
      images: 'images',
      loading: 'loading'
    }),
    messages: {
      get() {
        return this.chat?.messages ?? []
      },
      set(newName) {
        return newName
      }
    }
  },
  watch: {
    messages: {
      handler: function (messages) {
        this.messages = messages
        if (!this.loading) {
          this.scrollToBottom()
        }
      }
    },
    loading() {
      setTimeout(() => {
        this.scrollToBottom()
      }, 1000)
    },
    userId: {
      handler: function (id) {
        this.getUser(id)
      }
    }
  },
  created() {},
  async mounted() {
    const chatUserId = this.$cookies.get('chat-user-id')

    if (chatUserId) {
      try {
        const { data } = await usersApi.getParent({
          filter: {
            id: +chatUserId
          }
        })
        if (data?.result?.rows) {
          this.user = data.result.rows[0]
          await this.onChange()
        }
      } catch (error) {
        console.warn(error)
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('setChatImages', [])
    this.$router.beforeEach((to, from, next) => {
      if (to !== from) {
        this.$cookies.remove('chat-user-id')
        next()
      }
    })
  },
  methods: {
    ...mapMutations(['clearChat', 'setChats', 'setChat']),
    async onChange() {
      this.chat.messages ? this.chat.messages : []
      this.$socket.emit(
        'dashboard-chats-init',
        {
          userId: this.user.id
        },
        (initData) => {
          console.log('[dashboard-chats-init]: ', initData)
          if (initData?.success) {
            this.chatId = initData.result.id
            this.$socket.emit('dashboard-chats-enter', { chatId: this.chatId }, (enterData) => {
              console.log('[dashboard-chats-enter]: ', enterData)
              if (enterData?.success) {
                this.setChat(enterData.result)
              }
            })
          }
        }
      )
    },
    async sendImages() {
      for (const img of this.images) {
        try {
          const { data } = await chatApi.sendImages(img.formData)

          if (data?.success) {
            this.media.push(data.result[0])
          }
        } catch (error) {
          console.warn(error) // eslint-disable-line
          this.$store.commit(
            'setNoticeData',
            `<p style="margin: 2rem 3rem; font-size: 14px">Ошибка загрузки файла на сервер!</p>`
          )
          this.$store.commit('setNotice', 'ErrorNotice')
          setTimeout(() => {
            this.$store.commit('setNoticeData', null)
            this.$store.commit('setNotice', null)
          }, 5000)
        }
      }
    },
    async sendMessage() {
      if (this.message || this.images.length) {
        await this.sendImages()

        this.$socket.emit(
          'dashboard-messages-set',
          {
            chatId: this.chatId ?? this.chat?.id ?? this.$route.params.myId,
            text: this.message,
            media: this.media
          },
          (data) => {
            console.log('[dashboard-messages-set]: ', data)
            if (data?.success) {
              this.message = ''
              this.media = []
              this.$store.commit('setChatImages', [])
            } else {
              this.$store.commit(
                'setNoticeData',
                `<p style="margin: 2rem 3rem; font-size: 14px">Ошибка отправки сообщения на сервер! <br /> Пожалуйста, обновите страницу с чатом.</p>`
              )
              this.$store.commit('setNotice', 'ErrorNotice')
              setTimeout(() => {
                this.$store.commit('setNoticeData', null)
                this.$store.commit('setNotice', null)
              }, 5000)
            }
          }
        )
      }
    },
    scrollToBottom() {
      requestAnimationFrame(() => {
        this.$refs.block.scroll({
          top: this.$refs.block.scrollHeight,
          left: 0,
          behavior: 'smooth'
        })
      })
    },
    goBack() {
      this.$socket.emit(
        'dashboard-chats-leave',
        {
          chatId: this.chatId,
          type: 'dashboard-chat-3'
        },
        () => {
          this.clearChat()
          this.$router.push('/chats')
        }
      )
    },

    async getUser(id) {
      try {
        const { data } = await usersApi.getParent({
          filter: {
            id: +id
          }
        })
        this.users = data?.result?.rows
      } catch (error) {
        console.warn(error)
        this.resetUsers()
      }
    },

    async setUser(user) {
      this.userId = null
      this.user = user
      this.$cookies.set('chat-user-id', user.id)
      this.resetUsers()
      await this.onChange()
    },

    resetUsers() {
      this.users = []
    },

    getUserInfo(user) {
      const attr = user.attributes
      return `${attr.lastName} ${attr.firstName} ${attr.middleName}, тел: ${user.phone}`
    },

    deleteImage(id) {
      this.$store.commit('deleteChatImage', id)
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  &__wrapper {
    position: relative;
    display: flex;
    width: 100%;
  }

  &__address {
    width: 50%;
    margin: 0 !important;
  }

  &__dropdown {
    overflow: hidden;
    position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
    width: 50%;
    top: 2.5rem;
    background: $white;
    border: 1px solid $platinum;
    border-radius: 0.625rem;
    color: $wet-asphalt;
  }

  &__user {
    padding: 0.75rem;
    border-bottom: 1px solid $gainsborough;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $green-tea;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 80;
  }

  &__userInfo {
    margin: 0 0 0 1rem;

    &__text {
      margin: 0 0 0.25rem;
      font-size: 0.75rem;
    }
  }

  &__list {
    overflow-x: hidden;
    height: 70vh;
    padding: 2rem 2rem 0;
    margin: 0 0 1rem;

    &::-webkit-scrollbar {
      width: 10px;
      background-color: $light-gray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray;
    }

    &::-webkit-scrollbar-track {
      background-color: $light-gray;
      box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.2);
    }
  }

  &__images {
    display: flex;
    width: 100%;
    margin: 0 0 1rem;
  }

  &__image {
    position: relative;
    width: 5rem;
    height: 5rem;
    margin: 0 1rem 0 0;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__delete {
      position: absolute;
      top: -0.5rem;
      right: -0.75rem;
      z-index: 15;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
      height: 1.5rem;
      background: $wet-rainforest-light;
      border-radius: 50%;
      cursor: pointer;

      img {
        width: 0.6rem;
        height: 0.6rem;
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__input {
      width: 85%;
    }

    &__button {
      width: 13%;
    }
  }

  &__button {
    width: 30%;
  }
}
</style>
